<template>
    <div id="app" class="login-container">
        <div class="login-box">
            <!-- 头像区域 -->
            <div class="avatar-box">
                <img src="../assets/logo.png" alt/>
            </div>
            <!-- 登陆表单区域 -->
            <el-form
                    ref="loginFormRef"
                    :model="loginForm"
                    :rules="loginFormValid"
                    label-width="0px"
                    class="login-form"
            >
                <!-- 用户名 -->
                <el-form-item prop="username">
                    <el-input
                            clearable
                            v-model="loginForm.username"
                            placeholder="请输入手机号码"
                            prefix-icon="el-icon-search"
                            onkeyup="value=value.replace(/[^\d]/g,'')"
                            maxlength="11"
                    ></el-input>
                </el-form-item>
                <!-- 密码 -->
                <el-form-item prop="password">
                    <el-input
                            v-model="loginForm.password"
                            placeholder="请输入密码"
                            prefix-icon="el-icon-search"
                            type="password"
                            maxlength="18"
                            clearable
                    ></el-input>
                </el-form-item>
                <!-- 按键区域 -->
                <el-form-item class="login-btn">
                    <el-button type="primary" @click="login">登陆</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        props: {},
        data() {
            return {
                //表单填写
                loginForm: {
                    username: "",
                    password: ""
                },
                //验证表单绑定数据对象
                loginFormValid: {
                    //验证用户名是否合法
                    username: [
                        {required: true, message: "请输入手机号码", trigger: "blur"},
                        {min: 11, max: 11, message: "长度在 11 个字符", trigger: "blur"}
                    ],
                    //验证密码
                    password: [
                        {required: true, message: "请输入密码", trigger: "blur"},
                        {min: 8, max: 20, message: "长度在 8 到 18 个字符", trigger: "blur"}
                    ]
                }
            };
        },
        computed: {},
        created() {
        },
        mounted() {
        },
        watch: {},
        methods: {
            login() {
                this.$refs.loginFormRef.validate(async valid => {
                    if (!valid) return;
                    console.log("登录成功后");
                    this.$router.push("/index");
                });
            }
        },
        components: {}
    };
</script>

<style scoped>
    .login-container {
        background-image: url("../assets/2.png");
        position: relative;
    }

    .login-box {
        width: 450px;
        height: 300px;
        background-color: #fff;
        border-radius: 3px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .login-box .avatar-box {
        width: 130px;
        height: 130px;
        border: 1px solid #ccc;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 0 10px #ddd;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
    }

    .login-box .avatar-box img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #ccc;
    }

    .login-form {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
</style>
