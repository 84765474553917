<!--<div id="app">-->
<template>

    <el-container>

        <el-header style="text-align: center; font-size: 12px">

            <el-row :gutter="20" style="line-height: 0px;">
                <el-col :span="16">
                    <div class="grid-content bg-purple">

                        <el-input size="small" v-model="searchForm.name" placeholder="用户名称"></el-input>
                    </div>

                </el-col>
                <el-col :span="1">
                    <div class="grid-content bg-purple">
                        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    </div>
                </el-col>
            </el-row>
            <!-- 搜索筛选 -->

        </el-header>

        <el-main>

            <el-table :data="listData"
                      stripe
                      height="600px"
                      style="line-height: 00px;"
                      @row-click="showNicknameHistorye"
            >
                <el-table-column
                        prop="id"
                        label="id"
                        width="100px"
                >
                </el-table-column>
                <el-table-column
                        prop="nickname"
                        label="nickname"
                >
                </el-table-column>
                <el-table-column
                        prop="server_name"
                        label="server_name"

                >
                </el-table-column>
<!--                <el-table-column-->
<!--                        prop="world_type"-->
<!--                        label="world_type">-->
<!--                </el-table-column>-->

                <el-table-column
                        prop="update_time"
                        label="update_time">
                </el-table-column>


                <el-table-column
                        prop="count"
                        label="改名次数">
                </el-table-column>
            </el-table>


            <el-dialog title="历史昵称"
                       style="line-height: 00px;"
                       close-on-click-modal
                       @closed="dialogClose"
                       :visible.sync="isShowelDialog"
            >
                <el-table style="line-height: 00px;" :data="this.currentData">

                    <el-table-column property="nickname" label="nickname" width="150"></el-table-column>

                    <el-table-column property="server_name" label="server_name" width="200"></el-table-column>

                    <el-table-column property="record_time" label="record_time"></el-table-column>
                </el-table>
            </el-dialog>

        </el-main>
    </el-container>


</template>
<!--</div>-->
<script>

    // import {AxiosInstance as axios} from "axios";

    import {AxiosInstance as axios} from "axios";

    export default {
        props: {},
        data() {
            return {
                searchForm: {
                    page: 1,
                    limit: 10,
                    name: "",
                    phone: ""
                },
                listData: [
                    {
                        id: "",
                        nickname: "",
                        server_name: "",
                        world_type: 3,
                        arrary_nickname_historye: [],
                    }
                ],
                currentData: [],
                isShowelDialog: false
            };
        },
        computed: {},
        created() {
            this.searchForm.name = "猿4"
            this.search();
            // test();
        },
        mounted() {
        },
        watch: {},
        methods: {
            search() {
                // this.$message("搜索内容：" + this.searchForm.name);
                this.$http.get('/Api/getByName', {
                    params:
                        {
                            "name": this.searchForm.name
                        }
                }).then(response => {
                    this.listData = response.data.data

                    console.log(response.data.code);

                })
                // this.$http.get('/ban/test')
                //     .then(response => {
                //         console.log(response.data);
                //
                //     })


            },
            showNicknameHistorye(row, column, event) {
                this.currentData = row.arrary_nickname_historye
                this.isShowelDialog = true
                // console.log(row.arrary_nickname_historye);
            },

            dialogClose() {
                // console.log("关闭了");
                this.isShowelDialog = false
            }
        },
        components: {}
    };
</script>

<!--<style scoped>-->
<!--</style>-->
