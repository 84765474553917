import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import {Message, Notification} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/all.css'
import axios from 'axios'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard);


// axios.defaults.baseURL = 'https://api.lxlx.fun'
// axios.defaults.baseURL = 'http://ff14.tmos.icu'
axios.defaults.baseURL = 'https://ff14web.tmos.icu/app'
// axios.defaults.baseURL = 'http://47.75.114.21:18861'
// axios.defaults.baseURL = 'http://127.0.0.1:18861'
// axios.defaults.baseURL = 'http://127.0.0.1:18861'
Vue.prototype.$http = axios


//配置请求的根路径
// axios.defaults.baseURL = 'http://127.0.0.1:8888'
// //axios.defaults.baseURL = 'http://192.168.1.10:8888'
// axios.interceptors.request.use(config => {
//     console.log(config)
//     config.headers.Authorization = window.localStorage.getItem('token')
//     //在最后必须return config
//     return config;
// })
// // axios.defaults.baseURL = 'http://192.168.1.10:8888'
//
//axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.prototype.$message = Message;
Vue.prototype.$Notify = Notification;

Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')


