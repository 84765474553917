import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import login from '../views/login.vue'
import index from '../views/index.vue'
import 查询用户 from '../views/user/查询用户.vue'
import 最近24小时 from '../views/user/最近24小时.vue'
import 最近一个月 from '../views/user/最近一个月.vue'
import 统计 from '../views/user/统计.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'index',
        component: index,
        meta: {
            title: 'ff14',
            keepAlive: false, // 需要被缓存
        },
        children: [
            {path: '/index/page=0',  component: () => import("../views/user/统计.vue")},
            {path: '/index/page=1',  component: () => import("../views/user/查询用户.vue")},
            {path: '/index/page=2',  component: () => import("../views/user/最近24小时.vue")},
            {path: '/index/page=3',  component: () => import("../views/user/最近一个月.vue")}
        ]

    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
