<!--<div id="app">-->
<template>

    <el-container>

        <el-header style="text-align: center; font-size: 12px">

            <el-row :gutter="20" style="line-height: 0px;">
                <p style="color:green;font-size:20px;">总计：{{listData.length}}</p>
            </el-row>
            <!-- 搜索筛选 -->

        </el-header>

        <el-main>

            <el-table :data="listData"
                      stripe
                      height="600px"
                      style="line-height: 00px;"
            >
                <el-table-column
                        prop="id"
                        label="id"
                        width="100px"
                >
                </el-table-column>
                <el-table-column
                        prop="nickname"
                        label="nickname"
                >
                </el-table-column>
                <el-table-column
                        prop="server_name"
                        label="server_name"

                >
                </el-table-column>
                <el-table-column
                        prop="world_type"
                        label="world_type">
                </el-table-column>

                <el-table-column
                        prop="update_time"
                        label="update_time">
                </el-table-column>
            </el-table>
        </el-main>
    </el-container>


</template>
<!--</div>-->
<script>

    // import {AxiosInstance as axios} from "axios";

    import {AxiosInstance as axios} from "axios";

    export default {
        props: {},
        data() {
            return {
                searchForm: {
                    page: 1,
                    limit: 10,
                    name: "",
                    phone: ""
                },
                listData: [
                    {
                        id: "",
                        nickname: "",
                        server_name: "",
                        world_type: 3,
                    }
                ]
            };
        },
        computed: {},
        created() {
            this.searchForm.name = "猿2"
            this.search();
            // test();
        },
        mounted() {
        },
        watch: {},
        methods: {
            search() {
                // this.$message("搜索内容：" + this.searchForm.name);
                this.$http.get('/Api/queryLastMonth', {
                    params:
                        {
                            "name": this.searchForm.name
                        }
                }).then(response => {
                    this.listData = response.data.data

                    console.log(response.data.code);

                })
                // this.$http.get('/ban/test')
                //     .then(response => {
                //         console.log(response.data);
                //
                //     })


            }
        },
        components: {}
    };
</script>

<!--<style scoped>-->
<!--</style>-->
