<template>
    <div id="app">
        <el-container class="index-container">
            <!-- 页面头部区 -->
            <!--            <el-header>Header</el-header>-->
            <!-- 内容 -->
            <el-container>
                <el-aside width="200px">
                    <listMenu></listMenu>
                </el-aside>
                <!-- 内容 -->
                <el-main>
                    <div>
                                <router-view></router-view>
                    </div>
                    <!-- <transition name="fade">
                    </transition>-->
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import listMenu from "@/components/index-menu.vue";
    import IndexMain from "@/components/index-main.vue";

    export default {
        props: {},
        data() {
            return {};
        },
        computed: {},
        created() {
            // this.activeIndex = ;
            // console.log("打印4" + this.$route.fullPath)
            if (this.$route.fullPath === "/index") {
                this.$router.push('/index/page=0');
            }

        },
        mounted() {
        },
        watch: {},
        methods: {
            logout() {
                console.log("退出成功");
            }
        },
        components: {
            listMenu,
            IndexMain
        }
    };
</script>

<style scoped>
    .index-container {
        width: 100%;
        height: 100%;
        line-height: 260px;
    }

    /* //头部内容 */
    .el-header {
        background-color: #b3c0d1;
        color: #333;
        text-align: center;
        line-height: 60px;
    }

    /* //左侧菜单 */
    .el-aside {
        background-color: #545c64;
        color: #333;
    }
</style>