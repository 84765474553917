<template>
    <div>
        <el-menu
                :default-active="'/index/page=0'"
                :collapse="collapsed"
                collapse-transition
                router
                unique-opened
                class="el-menu-vertical-demo"
                background-color="#545c64"
                text-color="#fff"
                active-text-color="#ffd04b"
                @select="handleSelect"

        >

            <el-menu-item-group>
                <el-menu-item v-for="menu in allmenu" :index="'/'+menu.url" :key="menu.menuid">
                    <i class="el-icon-location" :class="menu.icon"></i>
                    <span>{{menu.menuname}}</span>
                </el-menu-item>
            </el-menu-item-group>

        </el-menu>


    </div>
</template>

<script>
    export default {
        props: {},
        data() {
            return {
                collapsed: false,
                allmenu: [],
                activeIndex: "/"
            };
        },
        computed: {},
        created() {
            let title = {
                data: [
                    {
                        menuid: 1,
                        icon: "el-icon-location",
                        menuname: "统计",
                        hasThird: null,
                        url: "index/page=0",
                    },
                    {
                        menuid: 2,
                        icon: "el-icon-location",
                        menuname: "查询用户",
                        hasThird: null,
                        url: "index/page=1"
                    },
                    {
                        menuid: 3,
                        icon: "el-icon-location",
                        menuname: "最近24小时",
                        hasThird: null,
                        url: "index/page=2"
                    }
                    ,
                    {
                        menuid: 4,
                        icon: "el-icon-location",
                        menuname: "最近一个月",
                        hasThird: null,
                        url: "index/page=3",

                    }
                ]
            };
            this.allmenu = title.data;
            this.activeIndex = this.$route.fullPath;
            // console.log("打印3"+this.activeIndex)
        },
        mounted() {
        },
        watch: {},
        methods: {
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },
            handleSelect(key, keyPath) {
                console.log("打印",key, keyPath);
                // this.sendMessage()
                this.setCurrentRoute(key,keyPath)
            },
            setCurrentRoute(key,keyPath) {
                // this.activeIndex = key;
                // console.log("打印2"+this.activeIndex)
            }
            // ,
            // sendMessage() {
            //     bus.$emit('on-reload', '使用bus派发的事件');
            // }
        },
        components: {}
    };
</script>

<style scoped>
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        border: none;
        text-align: left;
    }


</style>
