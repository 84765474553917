<!--<div id="app">-->
<template>

    <el-container>

        <el-header style="text-align: center; font-size: 12px; height: 20px">

            <el-row :gutter="20" style="line-height: 0px;">
                <!--                <p-->
                <!--                        总计：{{listData.length}}-->
                <!--                        style="font-size: 40px"-->
                <!--                />-->
                <!--                <p style="color:green;font-size:20px;">总计：{{listData.length}}</p>-->
                <!--                <el-col :span="16">-->
                <!--                    <div class="grid-content bg-purple">-->

                <!--                        <el-input size="small" v-model="searchForm.name" placeholder="用户名称"></el-input>-->
                <!--                    </div>-->

                <!--                </el-col>-->
                <!--                <el-col :span="1">-->
                <!--                    <div class="grid-content bg-purple">-->
                <!--                        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>-->
                <!--                    </div>-->
                <!--                </el-col>-->
            </el-row>
            <!--            &lt;!&ndash; 搜索筛选 &ndash;&gt;-->


        </el-header>

        <el-main>

            <el-table :data="listData"
                      :show-header="false"
                      style="line-height: 00px;"
                      border
                      default-expand-all
                      @row-click="gotoTaobao"
            >
                <el-table-column
                        type="index"
                        width="50px"
                >
                </el-table-column>
                <el-table-column
                        label="nickname"
                        width="125px"
                >
                    　　
                    <template slot-scope="scope">
                        　　　　<img :src="scope.row.mainPic" width="100" height="100" style="border-radius:10px"/>
                    </template>

                </el-table-column>

                <el-table-column
                        label="formattitle"

                >
                    <template slot-scope="scope">
                        　<p style="font-size:16px;  color:#1A1A1A">{{scope.row.formattitle}}</p>
                    </template>
                </el-table-column>


                <el-table-column
                        label="update_time"
                        width="300px"
                >

                    <template slot-scope="scope">
                        　<p style="font-size:16px;  color:orange">热销{{scope.row.twoHoursSales}}件(近2小时)</p>
                    </template>

                </el-table-column>


                <el-table-column
                >

                    <template slot-scope="scope">
                        <!--                        　<p style="font-size:16px;  color:orange">热销{{scope.row.twoHoursSales}}件(近2小时)</p>-->
                        <el-button type="primary"
                                   @click="share(scope.row)"
                                   v-clipboard:copy="share(scope.row)"
                                   @click.stop="deleteVisible = true"
                                   style="width:200px;height: 50px"
                        >
                            分享
                        </el-button>

                    </template>

                </el-table-column>

            </el-table>


            <el-dialog title="历史昵称"
                       style="line-height: 00px;"
                       close-on-click-modal
                       @closed="dialogClose"
                       :visible.sync="isShowelDialog"
            >
                <el-table style="line-height: 00px;" :data="this.currentData">

                    <el-table-column property="nickname" label="nickname" width="150"></el-table-column>

                    <el-table-column property="server_name" label="server_name" width="200"></el-table-column>

                    <el-table-column property="record_time" label="record_time"></el-table-column>
                </el-table>
            </el-dialog>

        </el-main>
    </el-container>


</template>
<!--</div>-->
<script>

    // import {AxiosInstance as axios} from "axios";

    import {AxiosInstance as axios} from "axios";

    export default {
        props: {},
        data() {
            return {
                searchForm: {
                    page: 1,
                    limit: 10,
                    name: "",
                    phone: ""
                },
                listData: [
                    {
                        id: "",
                        nickname: "",
                        server_name: "",
                        world_type: 3,
                        arrary_nickname_historye: [],
                    }
                ],
                currentData: [],
                isShowelDialog: false
            };
        },
        computed: {},
        created() {
            this.searchForm.name = "猿1"
            this.search();
            this.watch
            // test();
        },
        mounted() {
        },
        watch: {
            $route(to, from) {
                // console.log(to.path);
                this.search();
            }
        },
        methods: {
            search() {
                // const name = this.$route.query.cid
                // console.log(name);

                // this.$message("搜索内容：" + this.searchForm.name);
                this.$http.get('/cmzz/ranklist', {
                    params:
                        {
                            "cid": this.$route.query.cid
                        }
                }).then(response => {
                    this.listData = response.data.data
                    console.log(response.data);
                })
                // this.$http.get('/ban/test')
                //     .then(response => {
                //         console.log(response.data);
                //
                //     })


            },
            gotoTaobao(row, column, event) {
                // this.currentData = row.arrary_nickname_historye
                // window.localtion.href = row.couponClickUrl

                window.open(row.couponClickUrl, "_blank");

                // this.isShowelDialog = true
                // const name = this.$route.query.cid
                //
                // console.log(name);
            },

            dialogClose() {
                // console.log("关闭了");
                this.isShowelDialog = false
            },
            share(row) {
                if (row.tpwd == null) {
                    return row.formattitle +
                        "\n"
                        + row.shortUrl
                }

                // console.log("关闭了");
                // console.log(row.formattitle);
                // console.log(row.shortUrl);
                return row.formattitle +
                    "\n"
                    + row.shortUrl +
                    "\n"
                    + row.tpwd
            }
        },
        components: {}
    };
</script>
<!--import Clipboard from 'clipboard';-->
<!--<style scoped>-->
<!--</style>-->
