<template>
    <div>
        <p style="color:green;font-size:20px;line-height: 40px;">24小时遇到：{{listData.update_time24Count}}</p>
        <p style="color:green;font-size:20px;line-height: 40px;">24小时新面孔：{{listData.create_time24Count}}</p>
        <p style="color:green;font-size:20px;line-height: 40px;">一个月遇到：{{listData.update_timeLastMonthCount}}</p>
        <p style="color:green;font-size:20px;line-height: 40px;">一个月新增：：{{listData.create_timeLastMonthCount}}</p>
        <p style="color:green;font-size:20px;line-height: 40px;">用户表：：{{listData.userCount}}</p>
        <p style="color:green;font-size:20px;line-height: 40px;">用户日志表：：{{listData.userLogCount}}</p>
        <p style="color:green;font-size:20px;line-height: 40px;">盛大封禁表：：{{listData.banUserCount}}</p>
    </div>
</template>

<script>
    export default {
        props: {},
        data() {
            return {
                searchForm: {
                    page: 1,
                    limit: 10,
                    name: "",
                    phone: ""
                },
                listData: {
                    update_time24Count: 0,
                    create_time24Count: 0,
                    update_timeLastMonthCount: 0,
                    create_timeLastMonthCount: 0,
                    banUserCount: 0,
                    userLogCount: 0,
                    userCount: 0,
                }
            };
        },
        computed: {},
        created() {
            this.search();
        },
        mounted() {

        },
        watch: {},
        methods: {
            search() {
                // this.$message("搜索内容：" + this.searchForm.name);
                this.$http.get('/Api/getUserCount', {
                    params:
                        {
                            // "name": this.searchForm.name
                        }
                }).then(response => {
                    this.listData = response.data.data
                    console.log(response.data.data);
                })
            }
        },
        components: {},
    };
</script>

<style scoped>

</style>
